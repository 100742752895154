import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Anchorme } from 'react-anchorme';
import { Link } from 'gatsby';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faQuestionCircle, faListUl } from '@fortawesome/free-solid-svg-icons';

import ROUTES from "utils/routes";
import { withApolloClient } from "utils/graphqlSetup"
import './Datapage.scss';

import { useAuthentication } from 'components/firebase/AuthUserContext';
import getNumberOfDownloads from 'utils/trackFileDownload/getNumberOfDownloads';
import recordFileDownload from 'utils/trackFileDownload/recordFileDownload';

import reports_level_data from './codebooks/reports_codebook.pdf';
import clients_level_data from './codebooks/clients_codebook.pdf';
import issues_level_data from './codebooks/issues_codebook.pdf';
import issue_text_level_data from './codebooks/issue_text_codebook.pdf';
import network_level_data from './codebooks/network_codebook.pdf';
import bills_level_data from './codebooks/bills_codebook.pdf';
import lobbyists_level_data from './codebooks/lobbyists_codebook.pdf';

import { StaticImage } from 'gatsby-plugin-image';

// Avoid duplication in the below dataset setup.
const active_version = "/csv/v2024.10.01";
const disambiguation_note = <div><strong>Data Integrity:</strong> This dataset features our client-level identifier <code>lob_id</code>, which denotes a unique firm or interest group. <code>lob_id</code> allows researchers to match records irrespective of the underlying representation of the firm's name (e.g. "Apple" vs. "Apple Computer"; "Meta" vs. "Facebook"). In addition to allowing connections between LobbyView datasets, <code>lob_id</code> enables us to provide firm-level linkages to external corporate datasets, such as S&P Compustat and Moody's Orbis. We audit <code>lob_id</code> extensively and use a variety of approaches to improve data quality. If you see any potential errors -- firms matched together incorrectly -- please help us fix them using <a href="https://mit.co1.qualtrics.com/jfe/form/SV_3WurpOnakPraKGh" target="_blank" rel="noopener noreferrer">this link</a>. We will keep updating our data set based on the community efforts. Thank you very much!</div>;

const papers = {
  firmlevel: "Kim, In Song. (2017) 'Political Cleavages within Industry: Firm-level Lobbying for Trade Liberalization' American Political Science Review  111(1): pp. 1-20.",
  networks: "Kim, In Song and Dmitriy Kunisky. (2021) 'Mapping Political Communities: A Statistical Analysis of Lobbying Networks in Legislative Politics.' Political Analysis 29(3): pp. 317-336.",
  lobbyists: 'Rodrigo Garza, Nicholas Liu, Cole Ruehle. (2023) "Who Are America’s Lobbyists?: A Comprehensive Dataset of Histories, Donations, and Influence"',
  lv: "Kim, In Song (2018). “LobbyView: Firm-level Lobbying & Congressional Bills Database.” Working paper available from https://web.mit.edu/insong/www/pdf/lobbyview.pdf"
};

const datasets = {
  report_level: {
    title: "Lobbying Report Level Data",
    citation: papers["lv"],
    description: <div>This is the main LobbyView dataset which summarizes all quarterly and biannual LDA (Lobbying Disclosure Act) report filings between 1999 and 2024. This dataset can be connected to other LobbyView datasets as well as external data from S&amp;P Compustat and Moody&apos;s/BvD Orbis using identifiers provided by the LobbyView team to uniquely identify lobbying clients. For more information, please see the codebook and data download below. <br/><br/><strong>Updated October 2024</strong>.</div>,
    keyword: "Lobbying Disclosure Act of 1995, lobbying reports, firm-level analysis",
    related_publication: papers["firmlevel"],
    notes: disambiguation_note,
    csv_download: `${active_version}/reports_codebook.zip`,
    pdf: reports_level_data,
    legacy_csv: '/csv/v2021-2022/dataset___report_level.zip'
  },
  client_level:{
    title: "Client Level Data",
    citation: papers["lv"],
    description: <div>This dataset provides client-level information for the firms and interest groups that hire lobbyists. This data can be easily merged to the LobbyView Report Level Data in order to study firm-level political behavior and financial activities. We provide NAICS (North American Industry Classification System) codes for each firm where available in order to enable industry-level analyses.<br/><br/><strong>Updated October 2024</strong>.</div>,
    keyword: "Lobbying Disclosure Act of 1995, lobbying reports, firm-level analysis, corporate non-market strategy",
    related_publication: "",
    notes: disambiguation_note,
    csv_download: `${active_version}/clients_codebook.zip`,
    pdf: clients_level_data,
    legacy_csv: '/csv/v2021-2022/dataset___client_level.zip'
  },
  bill_level: {
    title: "Congressional Bill Level Data",
    citation: papers["lv"],
    description: <div>This dataset provides detailed information about congressional bills such as bill summary and bill sponsor. The data can be joined to lobbying reports via the Per-Issue Text Data, allowing researchers to study individual bills or patterns of lobbying.<br/><br/><strong>Updated October 2024</strong>.</div>,
    keyword: "Lobbying Disclosure Act of 1995, lobbying reports, firm-level analysis, bill lobbying, congress",
    related_publication: `${papers["firmlevel"]}\n\n${papers["networks"]}`,
    notes: "",
    csv_download: `${active_version}/bills_codebook.zip`,
    pdf: bills_level_data,
    legacy_csv: '/csv/v2021-2022/dataset___bills.zip'
  },
  issue_level: {
    title: "Per-Issue Report Data",
    citation: papers["lv"],
    description: <div>Each lobbying report described in the Report-Level dataset contains one or more issues that the lobbyist lobbied. This dataset maps the Report Level dataset to one or more issues per report. Issue categories are narrow enough to encompass specific policy objectives, and are enumerated within the data codebook for this dataset. Each issue also includes one or more entities that were lobbied, including chambers of government and federal agencies.<br/><br/><strong>Updated October 2024</strong>.</div>,
    keyword: "Lobbying Disclosure Act of 1995, lobbying reports, firm-level analysis, lobbied issues",
    related_publication: papers["firmlevel"],
    notes: "",
    csv_download: `${active_version}/issues_codebook.zip`,
    pdf: issues_level_data,
    legacy_csv: '/csv/v2021-2022/dataset___issue_level.zip'
  },
  network_data:{
    title: "Client-Legislator Network Data",
    citation: papers["lv"],
    description: <div>This dyadic dataset (client and politician level) allows researchers to conduct network analysis. We provide the number of times the given interest group lobbied on the congressional bills sponsored by the politician in each congressional session. Using this data set, users can construct an adjacency matrix to study legislative politics.<br/><br/><strong>Updated October 2024</strong>.</div>,
    keyword: "Lobbying Disclosure Act of 1995, lobbying reports, firm-level analysis, network analysis, lobbying networks",
    related_publication: papers["networks"],
    notes: disambiguation_note,
    csv_download: `${active_version}/network_codebook.zip`,
    pdf: network_level_data,
    legacy_csv: '/csv/v2021-2022/dataset__network_client_legislator.zip'
  },
  text_data: {
    title: "Per-Issue Text Data",
    citation: papers["lv"],
    description: <div>Each issue described under a single lobbying report contains a free-text field which clients use to describe their lobbying activities, including the congressional bills, public laws, and policies they lobbied. This dataset allows researchers to specific bills and laws lobbied. Users can merge this data with Report Level Data and Per-Issue Report Data using the report identifiers.<br/><br/><strong>Updated October 2024</strong>.</div>,
    keyword: "Lobbying Disclosure Act of 1995, lobbying reports, firm-level analysis, text data, text analysis of lobbying",
    related_publication: `${papers["firmlevel"]}\n\n${papers["networks"]}`,
    notes: "",
    csv_download: `${active_version}/issue_text_codebook.zip`,
    pdf: issue_text_level_data,
    legacy_csv: '/csv/v2021-2022/dataset__issue_text.zip'
  },
  lobbyist_level: {
    title: "Lobbyist Data",
    citation: `${papers["lv"]}\n\n${papers["lobbyists"]}`,
    description: <div>Although LDA filings list the names of individual lobbyists who worked on behalf of the client, these names do not have unique identifiers, limiting researchers' ability to study anything at the level of the lobbyist. In order to enable systematic study of lobbyists, we built a highly granular lobbyist-level dataset. We uniquely identify lobbyists, disambiguating matching names to individuals, and extract prior employment experience in covered positions (governemnt agencies, congressional committees, and legislative staff of legislators) using a natural language system. In the future we hope to add detailed demographic data to our records to enable the study of descriptive representation in lobbying.<br/><br/><strong>Updated October 2024</strong>.</div>,
    keyword: "Lobbying Disclosure Act of 1995, lobbying reports, contribution reports, firm-level analysis, network analysis, lobbying networks",
    related_publication: `${papers["firmlevel"]}\n\n${papers["networks"]}`,
    notes: "",
    csv_download: `${active_version}/lobbyists_text_codebook.zip`,
    pdf: lobbyists_level_data,
    extra_images: [
      <StaticImage src="../dataset/images/LobbyistMap.png" alt="Lobbyist Map"/>,
      <StaticImage src="../dataset/images/LobbyistsToConnectedCongressionalCommittees.png" alt="Lobbyists and Connected Congressional Committees"/>,
    ],
    legacy_csv: '/csv/v2021-2022/legacy_lobbyists.zip'
  }
}

const Datapage = (props) => {
  const [numberFileDownloads, setNumberFileDownloads] = useState(0)
  const [showWarning, setShowWarning] = useState(true)

  const authUser = useAuthentication()

  //updates the number of file downloads being show in real time
  const updateFileDownloadCountState = useCallback((fileName) => {
    getNumberOfDownloads(props.client, fileName).then((number) => {
      setNumberFileDownloads(number)
    }).catch((error) => {
      console.log(error)
    })
  }, [props.client])

  const dataset = datasets[props.id]
  useEffect(() => {
    if(dataset) {
      updateFileDownloadCountState(dataset.csv_download)
    }
  }, [dataset, updateFileDownloadCountState])


  //when a user clicks the file for download, the file link is hidden and calss for the update file function
  const clickDownloadFile = (fileName) => {
    const a = document.createElement('a'); //create dummy anchor element
    a.setAttribute("href", fileName); //set the data to download
    a.click(); //click to download file
    a.remove(); //remove anchor element

    recordFileDownload(props.client, fileName).then(
      () => updateFileDownloadCountState(fileName)
    ).catch(console.error)
  }


  const showDownloadContent = () => {
    if(authUser) {
      const csv_download = dataset.csv_download;

      return(
        <Col sm={12} md={12} lg={9}>
          <ul className="list-group">
            <b className="metric"> &nbsp;&nbsp; Download Files</b>
            <li className="list-group-item" onClick={() => clickDownloadFile(csv_download)}>
              CSV <FontAwesomeIcon className="download" icon={faFileDownload}/>
            </li>
          </ul>
        </Col>
      )
    } else {
      //when an unauthenticated user visits the page, a modal popup warning is show to the user
      //passing the location object as state so we will be redirected to this page on sign in

      const message = <span>You must be <b><Link to={ROUTES.SIGN_IN} state={{from: props.location}}>logged in</Link></b> to access dataset file downloads</span>

      return(
        <Col>
          <Modal show={showWarning} onHide={() => setShowWarning(false)}>
            <Modal.Header closeButton>Warning</Modal.Header>
            <Modal.Body>
              <p>{message}</p>
            </Modal.Body>
          </Modal>
          {message}
        </Col>
      )
    }
  }

  const showLegacyContent = () => {
    if(authUser) {
      const legacy_download = dataset.legacy_csv;

      return(
        <Col sm={12} md={12} lg={9}>
          <br/>
          <small>
           <strong>Deprecated Data Versions:</strong><br/>

          LobbyView data beginning with version v2024.10.01 is not compatible with prior LobbyView data. Researchers who are still using older data should migrate to current LobbyView data, which contains (in addition to new filings and bills) improvements in data quality and documentation. <strong>We do not recommend and do not offer support for researchers using legacy, deprecated data.</strong> As a courtesy to researchers who need legacy data for replication purposes, we make it available below, without warranty of any kind. <br/><br/>

          <a href={legacy_download}>Deprecated, Legacy Data (v2021)</a>
          </small>
        </Col>
      )
    }
    return "";
  }

  const openPDF = (pdf) => window.open(pdf)

  if (dataset) {
    return (
      <Container className="datapage">
        <h1>{dataset.title}</h1>
          <Row className="citation">
            <Col className="header" sm={12} md={12} lg={12}>
              <Dropdown>
                <Dropdown.Toggle variant="secondary">
                  <FontAwesomeIcon icon={faListUl}/> Cite Dataset
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="http://web.mit.edu/insong/www/research/lobbyview.bib" target="_blank" rel="noreferrer noopener">BibTex</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col className="background" sm={12} md={12} lg={12}>
              <strong>
                By using LobbyView data, you agree that any academic research you generate for presentation or publication will include the following citation:
              </strong><br/><br/>

              <Anchorme target="_blank" rel="noreferrer noopener">
                {dataset.citation}
              </Anchorme>
            </Col>
          </Row>

        <Row className="title">
          <Col sm={12} md={4} lg={3}>
              <b>Description</b>
          </Col>
          <Col sm={12} md={8} lg={9}>
            {dataset.description}
          </Col>
        </Row>

        {dataset.pdf && (
          <Row className="title">
            <Col sm={12} md={4} lg={3}>
                <b>Codebook &nbsp;
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        <div>This codebook includes a detailed description of each variable in the dataset, a diagram illustrating how to connect data from this dataset to other LobbyViewand external datasets, and notes about recent version updates. The codebook is included in the data download for your convenience.</div>
                      </Tooltip>
                    }>
                    <FontAwesomeIcon className="question" icon={faQuestionCircle}/>
                  </OverlayTrigger>
                </b>
            </Col>
            <Col sm={12} md={8} lg={9}>
              Click <span onClick={() => openPDF(dataset.pdf)}>here</span> for the codebook pdf, which is also included in the data download.
            </Col>
          </Row>
        )}
        
        <Row className="title">
          <Col sm={12} md={4} lg={3}>
              <b>Keywords</b>
          </Col>
          <Col sm={12} md={8} lg={9}>
            {dataset.keyword}
          </Col>
        </Row>

        <Row className="title">
          <Col sm={12} md={4} lg={3}>
              <b>Related Publication(s) &nbsp;
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip>
                      <div>Publications that use the data from this Dataset.</div>
                    </Tooltip>
                  }>
                  <FontAwesomeIcon className="question" icon={faQuestionCircle}/>
                </OverlayTrigger>
              </b>
          </Col>
          <Col className="related_publication" sm={12} md={8} lg={9}>
            {dataset.related_publication}
          </Col>
        </Row>

        {dataset.notes && (
          <Row className="title">
            <Col sm={12} md={4} lg={3}>
                <b>Notes</b>
            </Col>
            <Col sm={12} md={8} lg={9}>
              <Anchorme target="_blank" rel="noreferrer noopener">
                {dataset.notes}
              </Anchorme>
            </Col>
          </Row>
        )}

        {dataset.show_download_count!== false && (
          <>
          <Row>
            <Col className="metric" sm={12} md={12} lg={3}>
              <b>Data Download Count</b>
              <Row>
                <Col className="background">
                  {numberFileDownloads} Total Downloads
                </Col>
              </Row>
            </Col>

            {showDownloadContent()}
          </Row>
          <Row>
            <Col sm={12} md={4} lg={3}>
                
            </Col>
            {showLegacyContent()}
          </Row>
          </>
        )}

        <Row><Col sm={12} md={12} lg={12}>&nbsp;</Col></Row>

        {dataset.extra_images && (
          <>
            <Row>
              <Col md={12} lg={6}>
                {dataset.extra_images[0]}
              </Col>

              <Col md={12} lg={6}>
                {dataset.extra_images[1]}
              </Col>
            </Row>
          </>
        )}
        

        <Row className="footer">
          <Col>
            <center><Link to={ROUTES.DATASETS}><Button className="roundTealButton">Back</Button></Link></center>
          </Col>
        </Row>
      </Container>
    )
  } else {
    return(
      <Container className="datapage">
        <Row className="citation">
          <Col sm={12} md={12} lg={12}>
            <h1><center><b>Information for this dataset is not available.</b></center></h1>
          </Col>
        </Row>

        <Row className="footer">
          <Col>
            <center><Link to={ROUTES.DATASETS}><Button className="roundTealButton">Back</Button></Link></center>
          </Col>
        </Row>
      </Container>
    )
  }
}

Datapage.propTypes = {
  client: PropTypes.object.isRequired,
}

export default withApolloClient(Datapage)
